import React, { useEffect, useRef } from 'react';
import { Grid, useTheme, CardHeader, Card, Box, Container, Typography } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { TextButton } from '../../molecules/SomaClickable/index';
import Styles from './styles';
import { useSocketContext } from '../../../contexts/socketContext';
import { SomaTag } from '../../atoms/SomaTag';
import { useStreamContext } from '../../../contexts/streamContext';

function SurveyButton({
  option = { text: '' },
  index,
  height = 40,
  fontSize = 12,
  backgroundColor,
  selectedBackgroundColor,
  disabledTextColor,
  notSelectedBackgroundColor,
  disabledBorder = {},
  color,
  disabledSelectTextColor,
  vote,
  brandCheck,
  hasImage,
  hasEnded,
  surveyQuestion,
  winnerIndex,
}) {
  const theme = useTheme();
  backgroundColor = theme.palette.secondary.main;
  selectedBackgroundColor = theme.palette.primary.main;
  disabledTextColor = theme.palette.secondary.main;
  notSelectedBackgroundColor = theme.palette.secondary.dark;

  const { isAnswered, setAlternative, alternative } = useSocketContext();
  const animaleHeartSvg = isAnswered ? (
    <img
      src={theme.reaction.icons.answeredHeart}
      alt="reaction"
      style={{
        position: 'absolute',
        right: hasImage ? 16 : 24,
        alignSelf: 'center',
        width: '16px',
        height: '14px',
      }}
      color="inherit"
    />
  ) : (
    <img
      src={theme.reaction.icons.outlinedHeart}
      alt="reaction"
      style={{
        position: 'absolute',
        right: hasImage ? 16 : 24,
        alignSelf: 'center',
        width: '16px',
        height: '14px',
      }}
      color="inherit"
    />
  );

  return (
    <TextButton
      label={
        <>
          {hasEnded && (
            <Typography style={{ fontWeight: 600, marginRight: 4 }}>{`${
              option.porcentQuestion === null ? 0 : option.porcentQuestion
            }%`}</Typography>
          )}
          <Typography>{option.text}</Typography>
        </>
      }
      buttonStyles={{
        backgroundColor: winnerIndex === index ? theme.survey.winnerColor : backgroundColor,
        pointerEvents: hasEnded ? 'none' : 'auto',
        border:
          winnerIndex === index ? `1px solid ${theme.survey.winnerColor}` : `1px solid ${theme.palette.secondary.dark}`,
        margin: '4px 0',
        height,
        color,
        borderRadius: theme.survey.shape.borderRadius,
      }}
      labelStyles={{
        display: 'flex',
        '& p': {
          color: winnerIndex === index ? theme.palette.secondary.main : color,
          lineHeigth: 1.33,
          fontSize,
        },
      }}
      buttonProps={{
        onClick: () => {
          vote(option);
          setAlternative(index);
        },
        disabled: Boolean(isAnswered && surveyQuestion.active),
        endIcon:
          !hasEnded &&
          (brandCheck !== 'farm' && !hasImage ? (
            animaleHeartSvg
          ) : (
            <FavoriteBorderIcon
              style={{ fontSize: 9, position: 'absolute', right: hasImage ? 16 : 24, alignSelf: 'center' }}
              color="inherit"
            />
          )),
      }}
      disabledProps={{
        '& .MuiTypography-root': {
          color: alternative === index && disabledSelectTextColor,
        },
        '&.MuiButton-root.Mui-disabled': {
          backgroundColor: alternative === index ? selectedBackgroundColor : notSelectedBackgroundColor,
          color: alternative === index ? disabledSelectTextColor : disabledTextColor,
          ...disabledBorder,
        },
      }}
    />
  );
}

function Poll({ surveyQuestion, index: surveyIndex, hasEnded, winnerIndex }) {
  const theme = useTheme();
  const { socket, isAnswered, setIsAnswered, alternative } = useSocketContext();
  const { streamContent } = useStreamContext();
  const { brand } = streamContent;

  const brandCheck = brand.name;

  const cardHeaderClasses = Styles.useCardHeaderStyle(theme);
  const cardClasses = Styles.useCardStyle({ theme, isAnswered: surveyQuestion.active && isAnswered });
  const surveyScrollRef = useRef();

  const hasImage = surveyQuestion
    ? Boolean(Array.from(surveyQuestion.options || []).find((option) => Boolean(option.image)))
    : false;

  const vote = (option) => {
    socket.emit('voteSurvey', { questionId: surveyQuestion.id, optionId: option.id });
    setIsAnswered(true);
  };

  useEffect(() => {
    if (hasImage) {
      if (isAnswered && surveyQuestion.active) {
        surveyScrollRef.current.scrollLeft =
          140 * alternative + 12 * alternative - surveyScrollRef.current.clientWidth / 4;
        return;
      }
      surveyScrollRef.current.scrollLeft = 70;
    }
  }, [alternative, isAnswered, hasImage, surveyQuestion]);

  return (
    <Card classes={cardClasses}>
      <Grid container direction="column">
        {hasEnded && (
          <SomaTag
            label="enquete encerrada"
            tagStyles={{
              height: 24,
              marginBottom: 5,
              color: theme.survey.winnerColor,
              padding: '4px 12px',
              width: 135,
              whiteSpace: 'nowrap',
              border: `1px solid ${theme.survey.winnerColor}`,
            }}
          />
        )}
        <CardHeader
          title={`${theme.survey.questionText + surveyIndex}`}
          subheader={surveyQuestion.question}
          classes={cardHeaderClasses}
        />
        {!hasImage && (
          <Grid container direction="column" spacing={1}>
            {surveyQuestion.options.map((option, index) => (
              <Grid
                item
                key={option.id}
                xs={12}
                style={{ padding: 0, opacity: isAnswered && surveyQuestion.active && alternative !== index ? 0.3 : 1 }}
              >
                <SurveyButton
                  option={option}
                  index={index}
                  color={alternative === index ? theme.palette.secondary.main : theme.palette.primary.main}
                  disabledSelectTextColor={theme.palette.secondary.main}
                  disabledTextColor={theme.palette.primary.main}
                  vote={vote}
                  winnerIndex={winnerIndex}
                  brandCheck={brandCheck}
                  hasImage={hasImage}
                  hasEnded={hasEnded}
                  surveyQuestion={surveyQuestion}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {hasImage && (
          <Box style={{ overflow: 'auto', whiteSpace: 'nowrap', width: '100%' }} ref={surveyScrollRef}>
            {surveyQuestion.options.map((option, index) => (
              <Box
                key={option.id}
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  width: 140,
                  height: 210,
                  marginRight: 12,
                  backgroundImage: `url(${option.image})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  opacity: isAnswered && surveyQuestion.active && alternative !== index ? 0.3 : 1,
                  borderRadius: theme.poll.shape.borderRadius,
                }}
              >
                <Box
                  style={{
                    height: '50%',
                    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    borderBottomRightRadius: theme.poll.shape.borderRadius,
                    borderBottomLeftRadius: theme.poll.shape.borderRadius,
                  }}
                >
                  <Container style={{ bottom: 0, position: 'absolute', padding: '0 15px 12px' }}>
                    <SurveyButton
                      option={option}
                      index={index}
                      fontSize={10}
                      height={24}
                      backgroundColor="transparent"
                      selectedBackgroundColor={theme.palette.secondary.main}
                      disabledSelectTextColor={theme.palette.primary.main}
                      disabledTextColor={theme.palette.secondary.main}
                      notSelectedBackgroundColor="transparent"
                      disabledBorder={{ border: `1px solid ${theme.palette.secondary.main}` }}
                      color={alternative === index ? theme.palette.primary.main : theme.palette.secondary.main}
                      vote={vote}
                      winnerIndex={winnerIndex}
                      brandCheck={brandCheck}
                      hasImage={hasImage}
                      hasEnded={hasEnded}
                      surveyQuestion={surveyQuestion}
                    />
                  </Container>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Grid>
    </Card>
  );
}

export default Poll;
