import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import Styles from './styles';

function FooterLogin() {
  const theme = useTheme();

  const imageClasses = Styles.useImageStyle();
  const containerClasses = Styles.useContainerStyle();

  const image = get(theme.login.footer, 'logo', {});
  const text = get(theme.login.footer, 'text', null);

  return (
    <Grid container item classes={containerClasses}>
      <Box classes={imageClasses}>
        <img src={image.url} alt={image.alt} style={{ width: '100%' }} />
      </Box>
      <Typography>{text}</Typography>
    </Grid>
  );
}

export default FooterLogin;
