import React, { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { mobileZoomStyles } from './styles';

function MobileImage({ width, height, src: lowResImage, imageOnZoom: highResImage }) {
  const [panningDisabled, setPanningDisabled] = useState(true);
  const transformStyles = mobileZoomStyles({ width, height });
  const [actualImage, setActualImage] = useState(lowResImage);

  useEffect(() => {
    const img = new Image();
    img.onLoad = setActualImage(highResImage);
    img.src = highResImage;
  }, [highResImage]);

  return (
    <TransformWrapper
      initialScale={1}
      onZoom={(e) => setPanningDisabled(e.state.scale <= 1)}
      panning={{ disabled: panningDisabled }}
    >
      <TransformComponent contentStyle={transformStyles.component} wrapperStyle={transformStyles.wrapper}>
        <img width={width} height={height} alt="" src={actualImage} />
      </TransformComponent>
    </TransformWrapper>
  );
}
export { MobileImage };
