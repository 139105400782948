import { Grid, Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { isMobile } from 'react-device-detect';
import { somaEvents, useEventDispatch } from '../../../events';
import { SomaSelectQuantity } from '../../atoms';
import { SomaTag } from '../../atoms/SomaTag';
import { ProductPrice } from '../../molecules';
import { FigureButton } from '../../molecules/SomaClickable';
import style from './style';

function Product(props) {
  const { product, updateQuantity, isBag, onRemove, category } = props;

  const theme = useTheme();
  const productTypographyClasses = style.useProductTypography();
  const useBagProductTypography = style.useBagProductTypography();
  const bagGridClasses = style.useBagGridStyles();
  const prodButtonClasses = style.useProdButtonStyles(theme, isBag);
  const clusterTagClasses = style.useClusterTagStyles(theme);
  const gridContainerSize = style.useGridContainerSizeStyles();
  const gridContainerProductName = style.useGridContainerProductNameStyles();
  const gridContainerBagInfoSize = style.useGridContainerBagInfoSizeStyles();
  const sizeClasses = style.useSizeStyles();

  const dispatch = useEventDispatch();
  const openDetails = useCallback(() => {
    dispatch(somaEvents.onOpenProductDetails, { category, product });
  }, [category, product, dispatch]);

  const portrait = useMediaQuery('(orientation: portrait)');

  const productRef = useRef();

  const config = {
    direction: portrait && !isBag ? 'column' : 'row',
    portraitBool: portrait && !isBag,
  };

  const productThumb = product.images?.thumbs[0];

  const renderClusterTag = (productInfo, isOnBag = false) => {
    const { productClusters, price } = productInfo;
    const clusterList = Object.values(productClusters);
    const tagStyle = isOnBag ? { ...clusterTagClasses.onBag } : { ...clusterTagClasses.notOnBag };

    if (!productClusters || !price) return null;

    if (clusterList.includes('lojix-exclusivo'))
      return (
        <SomaTag
          label={theme.somaTag.exclusive.text}
          tagStyles={{
            backgroundColor: theme.somaTag.exclusive.backgroundColor,
            ...tagStyle,
          }}
        />
      );

    if (clusterList.includes('queridinho-voltou'))
      return (
        <SomaTag
          label={theme.somaTag.mostLiked.text}
          tagStyles={{
            backgroundColor: theme.somaTag.mostLiked.backgroundColor,
            ...tagStyle,
          }}
        />
      );

    return null;
  };

  const viewportWidth = window.innerWidth < 400;
  const renderBag = () => (
    <Grid container classes={bagGridClasses}>
      <Grid item style={{ marginRight: 16 }}>
        <FigureButton
          figure={
            <img
              src={product.images.thumbs[0]}
              alt="product"
              style={{ width: '100%', objectFit: 'cover', height: 140 }}
            />
          }
          buttonStyles={{ ...prodButtonClasses.bagProd }}
          gridStyles={{ flexDirection: 'column' }}
          buttonProps={{
            onClick: openDetails,
            disableElevation: true,
            disableFocusRipple: true,
            disableRipple: true,
          }}
        />
      </Grid>
      <Grid item classes={gridContainerSize}>
        <Grid container style={{ flexDirection: 'column' }}>
          <Grid item xs={12} classes={gridContainerProductName}>
            <Typography color="primary" classes={useBagProductTypography}>
              {product.productName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ProductPrice product={product} isBag priceStyles={{ color: theme.palette.primary.light }} />
          </Grid>
          {renderClusterTag(product, true)}
          <Box classes={gridContainerBagInfoSize}>
            <Grid classes={sizeClasses}>
              <Typography style={{ textTransform: 'uppercase', fontSize: 12 }}>
                {product.bagInfo.selectedItem.size}
              </Typography>
            </Grid>
            <SomaSelectQuantity
              number={product.bagInfo?.quantity}
              onClickMinus={() => {
                updateQuantity(product.bagInfo.uuid, -1);
                dispatch(somaEvents.onCartItemAmountDecreased, {
                  product: {
                    ...product,
                    sku: product.bagInfo.selectedItem.sku,
                    variant: product.bagInfo.selectedItem.size,
                    quantity: product.bagInfo.quantity + 1,
                  },
                });
              }}
              onClickPlus={() => {
                updateQuantity(product.bagInfo.uuid, 1);
                dispatch(somaEvents.onCartItemAmountIncreased, {
                  product: {
                    ...product,
                    sku: product.bagInfo.selectedItem.sku,
                    variant: product.bagInfo.selectedItem.size,
                    quantity: product.bagInfo.quantity + 1,
                  },
                });
              }}
            />
            <FigureButton
              figure={<img src={theme.bag.deleteIcon.src} height={22} alt="delete from bag" />}
              buttonStyles={{
                ...prodButtonClasses.deleteProd,
                width: 30,
                height: 39,
                marginLeft: viewportWidth ? 30 : 70,
                '&:hover': { backgroundColor: 'transparent' },
              }}
              buttonProps={{ onClick: () => onRemove(product) }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderDefault = () => (
    <Grid
      container
      ref={productRef}
      direction={config.direction}
      style={{ position: 'relative', backgroundColor: '#FFFFFF', height: isMobile ? 'initial' : '100%' }}
    >
      <Grid container item xs={12} style={{ position: 'relative' }}>
        {productThumb && (
          <FigureButton
            figure={<img src={product.images.thumbs[0]} alt="product" style={{ width: '100%' }} />}
            buttonStyles={{ ...prodButtonClasses.bagProd }}
            gridStyles={{ flexDirection: 'column' }}
            buttonProps={{
              onClick: openDetails,
              disableElevation: true,
              disableFocusRipple: true,
              disableRipple: true,
            }}
          />
        )}
        {!productThumb && <Skeleton width={178} height={267} variant="rect" />}
        {renderClusterTag(product)}
      </Grid>
      <Grid container item xs={12} style={{ padding: '5px 10px' }}>
        <Grid item xs={12}>
          <Typography color="primary" noWrap classes={productTypographyClasses}>
            {product.productName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProductPrice product={product} />
        </Grid>
      </Grid>
    </Grid>
  );

  return isBag ? renderBag() : renderDefault();
}

Product.defaultProps = {
  details: null,
  images: [],
  productName: null,
  isShowCasing: false,
};

Product.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  productName: PropTypes.string,
  details: PropTypes.shape({
    description: PropTypes.string,
    composition: PropTypes.string,
  }),
};

export default Product;
