import React, { useState, useContext, createContext, useCallback, useMemo } from 'react';

const SocketContext = createContext();

export default function SocketContextProvider({ children }) {
  const socketState = useState();
  const messageState = useState();
  const surveyState = useState({ surveyQuestion: null, isAnswered: false, alternative: -1, isRead: false });

  const value = useMemo(() => ({ socketState, messageState, surveyState }), [socketState, messageState, surveyState]);

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}

export const useSocketContext = () => {
  const { socketState, messageState, surveyState } = useContext(SocketContext);
  const [receivedMessage, setReceivedMessage] = messageState;
  const [socket, setSocket] = socketState;
  const [answeredSurvey, setAnsweredSurvey] = surveyState;
  const { surveyQuestion, isAnswered, alternative } = answeredSurvey;

  const setIsAnswered = useCallback(
    (value) => {
      setAnsweredSurvey((oldState) => ({
        ...oldState,
        isAnswered: typeof value === 'function' ? value(oldState.isAnswered) : value,
      }));
    },
    [setAnsweredSurvey]
  );
  const setAlternative = useCallback(
    (value) => {
      setAnsweredSurvey((oldState) => ({
        ...oldState,
        alternative: typeof value === 'function' ? value(oldState.alternative) : value,
      }));
    },
    [setAnsweredSurvey]
  );
  const setSurveyQuestion = useCallback(
    (value) => {
      setAnsweredSurvey((oldState) => ({
        ...oldState,
        surveyQuestion: typeof value === 'function' ? value(oldState.surveyQuestion) : value,
      }));
    },
    [setAnsweredSurvey]
  );

  return {
    socket,
    receivedMessage,
    setSocket,
    setReceivedMessage,
    isAnswered,
    setIsAnswered,
    alternative,
    setAlternative,
    surveyQuestion,
    setSurveyQuestion,
  };
};
