import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { usePrismicDocuments } from '@prismicio/react';
import { GetPrismicDocument } from '../../../services/prismic';
import { getDevice } from '../../../helpers/eventHelper';
import { useEventDispatch, somaEvents, errorTypes } from '../../../events';
import { HomeTemplate } from '../../templates';
import TextButton from '../../molecules/SomaClickable/textButton';
import { useStreamContext } from '../../../contexts/streamContext';
import config from '../../../config';

function HomePage(props) {
  const { onClick, liveActive, justify, sendEmail, email, onNavigateToSharedProduct, onNavigateToBag } = props;
  const dispatchEvent = useEventDispatch();
  const [prismicWelcomeButton, setPrismicWelcomeButton] = useState();
  const theme = useTheme();
  const { container: welcomeContainer } = theme.welcomePage;
  const { callToAction } = welcomeContainer;
  const streamEmailRequired = get(callToAction, 'mode');
  const inputStyleProps = get(callToAction, 'input');
  const { streamContent } = useStreamContext();
  const [response] = usePrismicDocuments();

  const dispatchHomePageEvents = useCallback(() => {
    const params = new URL(window.location.href).searchParams;
    const isSharedLink = params.get('utm_live') === somaEvents.liveSharedParam;

    if (isSharedLink) dispatchEvent(somaEvents.onLiveSharedAccessed);
    dispatchEvent(somaEvents.onEnter);
    dispatchEvent(somaEvents.deviceInfo, {
      type: 'Device information',
      device: getDevice(),
    });
  }, [dispatchEvent]);

  const onClickWelcome = useCallback(() => {
    try {
      dispatchHomePageEvents();
      onClick();
    } catch (error) {
      dispatchEvent(somaEvents.onError, {
        type: errorTypes.requestError,
        message: error.message,
        path: 'Welcome/index.js -> <SomaButton />',
        device: getDevice(),
        stack: error.stack,
      });
    }
  }, [dispatchEvent, dispatchHomePageEvents, onClick]);

  useEffect(() => {
    const prismicButtonText = GetPrismicDocument(streamContent, response)?.data.botao_entrada[0].text;
    setPrismicWelcomeButton(prismicButtonText);
  }, [streamContent, response]);

  // check if `bag` params exists on url then redirect to bag
  useEffect(() => {
    const url = new URL(window.location.href);
    const sharedBagParam = url.searchParams.get('bag');

    if (sharedBagParam) onNavigateToBag();
  }, [onNavigateToBag]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const sharedProductParam = url.searchParams.get(config.sharedProductNavigationParam);
    url.searchParams.delete(config.sharedProductNavigationParam);
    window.history.replaceState(null, null, url.toString());

    if (sharedProductParam) {
      streamContent.buttons.forEach((button) => {
        const product = button.products.find((p) => String(p.provider.productId) === String(sharedProductParam));

        if (product) {
          onNavigateToSharedProduct({ category: button, product });
          return true;
        }
        return false;
      });
    }
  }, [dispatchEvent, onClick, onNavigateToSharedProduct, streamContent.buttons, streamContent.is_live]);

  return (
    <HomeTemplate
      streamEmailRequired={streamEmailRequired}
      inputStyleProps={inputStyleProps}
      justify={justify}
      liveActive={liveActive}
      sendEmail={sendEmail}
      email={email}
    >
      <TextButton label={prismicWelcomeButton} buttonProps={{ onClick: onClickWelcome }} />
    </HomeTemplate>
  );
}

HomePage.defaultProps = {
  justify: 'flex-start',
  liveActive: true,
  onClick: () => {},
};

HomePage.propTypes = {
  justify: PropTypes.oneOf(['space-between', 'center', 'space-evenly', 'space-around', 'flex-start', 'flex-end']),
  liveActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HomePage;
