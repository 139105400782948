import React, { createContext, useContext, useState, useCallback } from 'react';

const ProductsPanelContext = createContext();

const INITIAL_STATE_FILTER = {
  filterBy: {
    sizes: [],
  },
};

const INITIAL_STATE_SEARCH = {
  searchText: '',
};

const INITIAL_STATE = {
  errorOnSearch: false,
  helperText: null,
  ...INITIAL_STATE_SEARCH,
  ...INITIAL_STATE_FILTER,
};

function ProductsPanelContextProvider({ children }) {
  const state = useState(INITIAL_STATE);

  return <ProductsPanelContext.Provider value={state}>{children}</ProductsPanelContext.Provider>;
}

const useProductPanelContext = () => {
  const [productsPanelState, setProductsPanelState] = useContext(ProductsPanelContext);

  const setHelperText = useCallback(
    (value, error) => {
      setProductsPanelState((prevState) => ({
        ...prevState,
        errorOnSearch: error,
        helperText: value,
      }));
    },
    [setProductsPanelState]
  );

  const addFilter = useCallback(
    (type, value) => {
      setProductsPanelState((prevState) => ({
        ...prevState,
        filterBy: {
          ...prevState.filterBy,
          [type]: [...prevState.filterBy[type], value],
        },
      }));
    },
    [setProductsPanelState]
  );

  const setProductsFiltered = useCallback(
    (value) => {
      setProductsPanelState((prevState) => ({
        ...prevState,
        productsFiltered: value,
      }));
    },
    [setProductsPanelState]
  );

  const removeFilter = useCallback(
    (type, value) => {
      setProductsPanelState((prevState) => ({
        ...prevState,
        filterBy: {
          ...prevState.filterBy,
          [type]: prevState.filterBy[type].filter((item) => item !== value),
        },
      }));
    },
    [setProductsPanelState]
  );

  const setSearchText = useCallback(
    (value) => {
      setProductsPanelState((prevState) => ({ ...prevState, searchText: value }));
    },
    [setProductsPanelState]
  );

  const clearFilter = useCallback(() => {
    setProductsPanelState((prevState) => ({ ...prevState, ...INITIAL_STATE_FILTER }));
  }, [setProductsPanelState]);

  const clearSearch = useCallback(() => {
    setProductsPanelState((prevState) => ({ ...prevState, ...INITIAL_STATE_SEARCH }));
  }, [setProductsPanelState]);

  const clearFiltersAndSearch = useCallback(() => {
    setProductsPanelState(INITIAL_STATE);
  }, [setProductsPanelState]);

  return {
    productsPanelState,
    addFilter,
    removeFilter,
    setProductsFiltered,
    clearFiltersAndSearch,
    setHelperText,
    clearSearch,
    clearFilter,
    setSearchText,
  };
};

export { ProductsPanelContextProvider, useProductPanelContext };
