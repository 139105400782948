import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ToggleButton from '@material-ui/lab/ToggleButton';
import Styles from './styles';

function SelectSize(props) {
  const theme = useTheme();
  const { items, setSelectedSize, selectedSize, scrollTarget } = props;
  const containerClasses = Styles.useContainerStyle();
  const gridClasses = Styles.useGridStyle(theme);
  const buttonClasses = Styles.useButtonStyles(theme);
  const textClasses = Styles.useTextStyles();

  useEffect(() => {
    if (items.length === 1) setSelectedSize(items[0].size);
  }, [setSelectedSize, items]);

  return (
    <Container classes={containerClasses} disableGutters={false} ref={scrollTarget}>
      <Typography className={textClasses.title}>Tamanhos</Typography>
      {Array.isArray(items.sort((a, b) => a.size - b.size)) && (
        <Grid classes={gridClasses}>
          {items.map((item) => (
            <Grid item key={item.size}>
              <ToggleButton
                classes={buttonClasses}
                value={item}
                disableRipple
                disabled={!item.availableQuantity}
                selected={selectedSize === item.size}
                onChange={() => setSelectedSize(item.size)}
              >
                {item.size}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      )}
      {selectedSize === 'notSelected' && <Typography className={textClasses.text}>selecione um tamanho</Typography>}
    </Container>
  );
}

SelectSize.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      size: PropTypes.string,
      availableQuantity: PropTypes.number,
    })
  ).isRequired,
  setSelectedSize: PropTypes.func.isRequired,
};

export default SelectSize;
