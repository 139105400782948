import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../helpers/currencyHelper';
import style from './styles';
import { SomaTag } from '../../atoms/SomaTag';

function ProductPrice({ product, priceStyles, gridPriceStyle, discontPriceColor, fontSize, isBag }) {
  const { price, listPrice } = product;
  const hasDiscount = Number(listPrice) - Number(price) > 0;
  const theme = useTheme();

  const formatedPrices = {
    priceWithoutDiscont: formatCurrency(product.listPrice, theme.i18n),
    price: formatCurrency(product.price, theme.i18n),
  };
  const priceClasses = style.usePriceTypography({ fontSize, discontPriceColor, ...priceStyles });
  const gridClasses = style.useGridClasses({ ...gridPriceStyle });
  const gridItemClasses = style.useGridItemClasses();

  return (
    <Grid container spacing={1} classes={gridClasses}>
      {hasDiscount && (
        <Grid classes={gridItemClasses}>
          <Typography display="inline" classes={priceClasses} variant={isBag ? 'subtitle1' : 'subtitle2'}>
            {formatedPrices.priceWithoutDiscont}
          </Typography>
        </Grid>
      )}
      <Grid classes={gridItemClasses}>
        {product.price ? (
          <Typography
            display="inline"
            classes={priceClasses}
            style={{ fontSize: isBag ? 12 : fontSize }}
            variant={hasDiscount ? 'body2' : 'body1'}
          >
            {formatedPrices.price}
          </Typography>
        ) : (
          <SomaTag
            label={theme.somaTag.stockOut.text}
            tagStyles={{
              backgroundColor: theme.somaTag.stockOut.backgroundColor,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

ProductPrice.defaultProps = {
  fontSize: 12,
};

ProductPrice.propTypes = {
  fontSize: PropTypes.number,
};

export default ProductPrice;
