import { Dialog, DialogContent, Fab, Typography } from '@mui/material';
import React, { useState } from 'react';
import CONFIG from '../../../config';
import { SomaDrawer } from '../../organisms';
import { TextButton } from '../SomaClickable';
import Styles from './styles';

const closeModalIcon = `${CONFIG.assetsUrl}/common/icons/modal-close-icon.svg`;
function DesktopDialog({ active, handleInteraction, newFeatureViewed, feature }) {
  const closeButtonClasses = Styles.useCloseButtonStyles();
  const dialogClasses = Styles.useDialogStyles();
  const buttonClasses = Styles.useButtonStyles();
  const containerClasses = Styles.useContainerStyles();
  return (
    <Dialog classes={dialogClasses} onClose={() => handleInteraction()} open={!newFeatureViewed && active}>
      <Fab classes={closeButtonClasses} onClick={() => handleInteraction()} disableRipple disableFocusRipple>
        <img src={closeModalIcon} alt="close_modal" width="14px" height="14px" />
      </Fab>
      <DialogContent classes={containerClasses}>
        <img src={feature.icon} alt="new_feature_icon" width="72px" height="72px" />
        <Typography variant="h1">{feature.title}</Typography>
        <Typography style={{ marginBottom: 16 }}>
          {feature.text} <Typography variant="span"> {feature.name}</Typography>.
        </Typography>
        <Typography>{feature.subText}</Typography>
        <TextButton
          label={feature.button}
          buttonStyles={{ ...buttonClasses.button }}
          labelStyles={{ ...buttonClasses.label }}
          buttonProps={{ onClick: () => handleInteraction() }}
        />
      </DialogContent>
    </Dialog>
  );
}

function MobileDrawer({ active, handleInteraction, newFeatureViewed, feature }) {
  const buttonClasses = Styles.useButtonStyles();

  const containerClasses = Styles.useContainerStyles();
  const drawerClasses = Styles.useDrawerStyles();
  return (
    <SomaDrawer
      anchorDirection="bottom"
      isOpen={!newFeatureViewed && active}
      onClose={() => handleInteraction()}
      drawerStyles={{ ...drawerClasses }}
    >
      <DialogContent classes={containerClasses}>
        <img src={feature.icon} alt="new_feature_icon" width="72px" height="72px" />
        <Typography variant="h1">{feature.title}</Typography>
        <Typography style={{ marginBottom: 16 }}>
          {feature.text} <Typography variant="span"> {feature.name}</Typography>.
        </Typography>
        <Typography>{feature.subText}</Typography>
        <TextButton
          label={feature.button}
          buttonStyles={{ ...buttonClasses.button }}
          labelStyles={{ ...buttonClasses.label }}
          buttonProps={{ onClick: () => handleInteraction() }}
        />
      </DialogContent>
    </SomaDrawer>
  );
}

function NewFeature(props) {
  const { feature, portrait, active } = props;
  const storageKey = `was_${feature.name}_viwed`;
  const [newFeatureViewed, setNewFeatureViewed] = useState(!!localStorage.getItem(storageKey));

  const handleInteraction = () => {
    setNewFeatureViewed(true);
    localStorage.setItem(storageKey, true);
  };

  if (newFeatureViewed) return null;
  return portrait ? (
    <MobileDrawer
      feature={feature}
      active={active}
      newFeatureViewed={newFeatureViewed}
      handleInteraction={handleInteraction}
    />
  ) : (
    <DesktopDialog
      feature={feature}
      active={active}
      newFeatureViewed={newFeatureViewed}
      handleInteraction={handleInteraction}
    />
  );
}
export default NewFeature;
