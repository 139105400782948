import React, { useState } from 'react';
import { Button, FormControl, Grid, TextField, InputAdornment, IconButton, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Styles from './styles';

function FormLogin(props) {
  const { onLogin, setUser, setPassword, error } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const theme = useTheme();
  const inputClasses = Styles.useInputStyle();
  const buttonClasses = Styles.useButtonStyle();
  const fullWidthClasses = Styles.fullWidthStyle();

  return (
    <Grid container item direction="column" lg={8}>
      <Grid item>
        <FormControl classes={fullWidthClasses}>
          <TextField
            error={error}
            id="user-input"
            variant="outlined"
            label={theme.login.form.inputUser.text}
            onChange={(e) => setUser(e.target.value)}
            classes={inputClasses}
          />
        </FormControl>
      </Grid>
      <Grid item classes={fullWidthClasses}>
        <FormControl classes={fullWidthClasses}>
          <TextField
            error={error}
            id="password-input"
            variant="outlined"
            label={theme.login.form.inputPassword.text}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            classes={inputClasses}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyUp={(event) => {
              if (event.key.toLowerCase() === 'enter') {
                onLogin(event);
              }
            }}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={onLogin} size="large" classes={buttonClasses}>
          {theme.login.form.button.text}
        </Button>
      </Grid>
    </Grid>
  );
}
FormLogin.defaultProps = {
  setPassword: () => {},
  setUser: () => {},
};

FormLogin.propTypes = {
  setPassword: PropTypes.func,
  setUser: PropTypes.func,
  onLogin: PropTypes.func.isRequired,
};

export default FormLogin;
