import React from 'react';
import { Grid } from '@material-ui/core';
import { userSpinnerStyles } from './styles';

function Loading() {
  const spinnerClasses = userSpinnerStyles();

  return (
    <Grid container spacing={0} justify="center" alignItems="center" classes={spinnerClasses}>
      <Grid item classes={spinnerClasses} />
    </Grid>
  );
}

export default Loading;
