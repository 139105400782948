import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import { Box, useTheme, Container, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Checkout } from '../../molecules';
import SomaGridList from '../ProductsList/SomaGridList';
import Styles from './styles';
import { SalesWomanContext, LocalStorageContext } from '../../../hooks/contexts';
import { save } from '../../../helpers/localStorageHelper';
import { useEventDispatch, somaEvents } from '../../../events';
import CONFIG from '../../../config';
import { useBagContext } from '../../../contexts/bagContext';
import { useStreamContext } from '../../../contexts/streamContext';
import { TextButton, ComposedButton } from '../../molecules/SomaClickable';

const initialCheckoutValues = {
  total: 0,
  subtotal: 0,
  discount: 0,
};

function SomaBag(props) {
  const { brand, closeFunction } = props;
  const userStore = useContext(LocalStorageContext);
  const [products, setProducts] = useState(userStore.get('bag'));
  const portrait = useMediaQuery('(orientation: portrait)');

  const { saleswomanCouponCode } = useContext(SalesWomanContext);
  const { setBagBadgeCount } = useBagContext();
  const theme = useTheme();
  const boxClasses = Styles.useBoxStyle();
  const emptyBoxClasses = Styles.useEmptyBoxStyle(theme);
  const mainClasses = Styles.useMainStyle();
  const footerClasses = Styles.useFooterStyle();
  const buttonClasses = Styles.useButtomStyle(theme);
  const boxTitleClasses = Styles.useboxTitleStyle();
  const titleClasses = Styles.useTitleStyles();
  const TotalProductsClasses = Styles.useTotalProductsStyle();

  const { streamContent } = useStreamContext();
  const { sales_channel: salesChannel, buttons } = streamContent;
  const dispatchEvent = useEventDispatch();

  const updateProducts = useCallback(
    (bagProducts) => {
      if (!userStore) return;

      const quantities = bagProducts.map((product) => product.bagInfo.quantity);
      const total = quantities.reduce((previous, current) => previous + current, 0);

      setBagBadgeCount(total);
      userStore.set('bag', bagProducts);
      setProducts([...userStore.get('bag')]);
      save(userStore);
    },
    [setBagBadgeCount, userStore]
  );

  const doCheckout = () => {
    if (!products) return;

    const itemsParam = products.map((product) => {
      const { bagInfo } = product;

      const { sku, sellerId } = bagInfo.selectedItem;
      if (brand === 'farmUS') {
        return `id[]=${sku}&`.repeat(bagInfo.quantity);
      }
      return `sku=${sku}&qty=${bagInfo.quantity}&seller=${sellerId}`;
    });

    const { baseUrl, path, utmParams } = theme.checkout;

    const saleswomanParams = saleswomanCouponCode
      ? ['utm_medium=linkvendedor', 'utm_source=vendedora', `utm_campaign=${saleswomanCouponCode}`]
      : [];

    const salesChannelQuery = `sc=${salesChannel}`;
    const currentQueryString = window.location.search.replace('?', '');
    const queryString = [salesChannelQuery, ...itemsParam, ...saleswomanParams, utmParams, currentQueryString]
      .filter(Boolean)
      .join('&');
    const checkoutUrl = `${baseUrl + path}?${queryString}`;

    dispatchEvent('consoleLog', checkoutUrl);

    window.open(checkoutUrl, '_blank');

    dispatchEvent(somaEvents.onCheckoutStarted, {
      products: products?.map((product) => ({
        ...product,
        sku: product.bagInfo.selectedItem.sku,
        variant: product.bagInfo.selectedItem.size,
        quantity: product.bagInfo.quantity,
      })),
    });
  };

  const buildUrlForShare = useCallback(() => {
    if (!Array.isArray(products)) return '';

    const productIds = products.reduce(
      (unique, item) => (unique.includes(item.productId) ? unique : [...unique, item.productId]),
      []
    );

    const params = productIds.map((id) => ({
      productId: id,
      items: products.reduce(
        (productsArr, product) =>
          product.productId === id
            ? [...productsArr, { sku: product.bagInfo.selectedItem.sku, qty: product.bagInfo.quantity }]
            : productsArr,
        []
      ),
    }));

    const itemsParam = Buffer.from(JSON.stringify(params)).toString('base64');

    const saleswomanParams = saleswomanCouponCode ? [`${CONFIG.vendorParam}${saleswomanCouponCode}`] : [];

    const queryString = [...saleswomanParams, `bag=${itemsParam}`].filter(Boolean).join('&');

    return `${window.location.origin + window.location.pathname}?${queryString}`;
  }, [products, saleswomanCouponCode]);

  const [bagUrl, setBagUrl] = useState(buildUrlForShare());

  useEffect(() => {
    setBagUrl(buildUrlForShare());
  }, [buildUrlForShare]);

  useEffect(() => {
    const bagProducts = userStore.get('bag');
    updateProducts(bagProducts);
  }, [updateProducts, userStore]);

  const onRemove = (productToRemove) => {
    const update = products.filter((bagProduct) => bagProduct.bagInfo.uuid !== productToRemove.bagInfo.uuid);
    updateProducts(update);
    dispatchEvent(somaEvents.onCartItemRemoved, {
      product: {
        ...productToRemove,
        sku: productToRemove.bagInfo.selectedItem.sku,
        variant: productToRemove.bagInfo.selectedItem.size,
        quantity: productToRemove.bagInfo.quantity,
      },
    });
  };

  const updateQuantity = (uuid, modifier) => {
    const bagProducts = userStore.get('bag');
    const currentProduct = bagProducts.find((sp) => sp.bagInfo.uuid === uuid);
    currentProduct.bagInfo.quantity += modifier;
    if (currentProduct.bagInfo.quantity < 1) {
      currentProduct.bagInfo.quantity = 1;
      return;
    }
    updateProducts(bagProducts);
  };

  const handleClose = () => {
    closeFunction();
  };

  const allProducts =
    buttons
      ?.map((button) => button.products)
      .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []) || [];

  const productsBagFilter = products.reduce((accumulator, product) => {
    let bagProduct = allProducts.find((prod) => prod.id === product.productId);
    if (!bagProduct) {
      return accumulator;
    }
    bagProduct = { ...product, ...bagProduct };
    accumulator.push(bagProduct);
    return accumulator;
  }, []);

  const checkout = productsBagFilter.reduce(
    (previous, current) => {
      const { quantity } = current.bagInfo;
      const total = previous.total + current.price * quantity;
      const subtotal = previous.subtotal + current.listPrice * quantity;
      const discount = subtotal - total;

      return { total, subtotal, discount };
    },
    { ...initialCheckoutValues }
  );

  return (
    <>
      {productsBagFilter && productsBagFilter.length > 0 && (
        <Box classes={boxClasses}>
          <Box classes={boxTitleClasses}>
            <Typography classes={titleClasses}>mochila</Typography>
            <Typography classes={TotalProductsClasses}>{`${productsBagFilter.length} itens`}</Typography>
          </Box>

          <main className={mainClasses.root}>
            <SomaGridList
              products={productsBagFilter}
              onRemove={onRemove}
              updateQuantity={updateQuantity}
              cols={1}
              isBag
            />
          </main>

          <footer className={footerClasses.root}>
            <Checkout
              totalValue={checkout.total}
              subtotalValue={checkout.subtotal}
              discountValue={checkout.discount}
              bagUrl={bagUrl}
              onCheckout={doCheckout}
              products={products}
            />
          </footer>
        </Box>
      )}
      {(!productsBagFilter || productsBagFilter.length === 0) && (
        <Box classes={emptyBoxClasses}>
          <ComposedButton
            figure={
              brand !== 'farm' ? null : (
                <img
                  src={theme.bag.emptyIcon.src}
                  alt={theme.bag.emptyIcon.alt}
                  style={{ margin: portrait && '0 0 0 -10px' }}
                />
              )
            }
            buttonProps={{ disableElevation: true, disableFocusRipple: true, disableRipple: true }}
            gridStyles={{ justifyContent: portrait && 'flex-start' }}
            buttonStyles={{ ...buttonClasses.composed }}
            labelStyles={{ ...buttonClasses.label, textAlign: portrait && 'justify' }}
            label={theme.bag.emptyBag}
          />
          <Container className="center-container">
            <TextButton
              buttonStyles={{ ...buttonClasses.basic }}
              labelStyles={{ color: theme.palette.secondary.main }}
              label={theme.bag.continueShopping.text}
              buttonProps={{
                onClick: handleClose,
              }}
            />
          </Container>
        </Box>
      )}
    </>
  );
}

export default SomaBag;
