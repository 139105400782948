import React from 'react';
import { Typography } from '@material-ui/core';
import Styles from './styles';

function ProductDescription(props) {
  const { description } = props;
  const descriptionStyle = Styles.useDescriptionStyle();

  if (typeof description === 'string') {
    return <Typography className={descriptionStyle.text}>{description}</Typography>;
  }
  const paragraphs = new Set();
  paragraphs.add({ id: 1, typographies: [] });

  if (Array.isArray(description)) {
    description.forEach((descriptionItem, index) => {
      const currentDialogContentText = [...paragraphs.values()].pop();

      if (['br', 'hr'].includes(descriptionItem.nodeType)) {
        paragraphs.add({ id: currentDialogContentText.id + 1, typographies: [] });
      } else {
        const nodeTypeCustomStyle = descriptionStyle[descriptionItem.nodeType];
        const className = [descriptionStyle.text, nodeTypeCustomStyle].filter(Boolean).join(' ');
        const key = descriptionItem.nodeType + index;
        currentDialogContentText.typographies.push(
          <Typography key={key} className={className} component="span">
            {descriptionItem.content}
          </Typography>
        );
      }
    });
  }

  return [...paragraphs.values()].map((paragraph) => (
    <Typography key={paragraph.id}>{paragraph.typographies}</Typography>
  ));
}

export default ProductDescription;
