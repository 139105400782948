import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { Grid, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LocalStorageContext } from '../../../../hooks/contexts';
import Styles from './styles';
import { save } from '../../../../helpers/localStorageHelper';
import { useChatContext } from '../../../../contexts/chatContext';

function SomaChatMessageDisplay(props) {
  const { showEmail } = props;
  const portrait = useMediaQuery('(orientation: portrait)');
  const userStore = useContext(LocalStorageContext);
  const { chatMessages: messages, setChatMessages } = useChatContext();
  const listRef = useRef();

  const updateReadMessages = useCallback(
    (times) => {
      if (!messages) return;
      const updateMessages = [
        ...messages.map((message) => {
          if (times.includes(message.time)) {
            message.userRead = true;
          }
          return message;
        }),
      ];

      setChatMessages(updateMessages);
      const chat = userStore.get('chat');
      chat.set('messages', updateMessages);
      userStore.set('chat', chat);
      save(userStore);
    },
    [messages, setChatMessages, userStore]
  );

  const listClasses = Styles.useListStyle();
  const bodyMessage = Styles.bodyMessageStyle();
  const headerMessage = Styles.headerMessageStyle();

  useEffect(() => {
    if (!Array.isArray(messages)) return;
    if (listRef.current) listRef.current.scrollTop = listRef.current.scrollHeight;
    const unreadMessages = messages.map((message) => (message.userRead ? null : message.time)).filter(Boolean);
    if (unreadMessages.length > 0) updateReadMessages(unreadMessages);
  }, [messages, updateReadMessages]);

  return (
    Array.isArray(messages) && (
      <div className={listClasses.root} ref={listRef}>
        {messages.map((message) => {
          const { text, time, email, name } = message;
          const isReplyMessage = !!message.replyingTo;
          const messageHour = new Date(time).toLocaleTimeString();

          return (
            <Grid
              container
              style={{
                padding: portrait ? '7px 0px' : '7px 10px',
              }}
              key={message.id || message.email + message.time}
            >
              <Grid item xs={12}>
                <ListItemText classes={headerMessage} secondary={messageHour} />
                {(showEmail || isReplyMessage) && <ListItemText classes={bodyMessage} secondary={email} />}
              </Grid>
              <Grid item xs={12}>
                <ListItemText secondary={name} classes={bodyMessage} />
                <ListItemText primary={text} classes={bodyMessage} />
              </Grid>
            </Grid>
          );
        })}
      </div>
    )
  );
}

SomaChatMessageDisplay.defaultProps = {
  showEmail: false,
};

SomaChatMessageDisplay.propTypes = {
  showEmail: PropTypes.bool,
};

export default SomaChatMessageDisplay;
