import React, { useState, useEffect } from 'react';
import { useTheme, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../helpers/currencyHelper';
import Styles from './styles';
import { useStreamContext } from '../../../contexts/streamContext';

function InstallmentText({ align, product, bagInstallments, fontSize, installmentStyle, fullInstallmentText }) {
  const theme = useTheme();
  const { streamContent } = useStreamContext();
  const { buttons } = streamContent;
  const formattedPricesClasses = Styles.useFormattedPricesClasses({ theme, fontSize, ...installmentStyle });
  const [productInstallments, setProductInstallments] = useState();

  useEffect(() => {
    if (!product || !Array.isArray(buttons)) return;

    const allProducts = buttons
      .map((prod) => prod.products)
      .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []);

    const productFinded = allProducts.find((prod) => prod.id === product.id);

    if (productFinded) {
      const installmentFinded = [...productFinded.items].find((item) => !!item.installments);

      if (installmentFinded) {
        setProductInstallments({
          installments: installmentFinded.installments.numberOfInstallments,
          value: installmentFinded.installments.value,
        });
      }
    }
  }, [product, buttons]);

  const installmentsNumber = productInstallments || bagInstallments;
  const installmentFormattedPrice = formatCurrency(installmentsNumber?.value, theme.i18n);

  if (!installmentsNumber) return null;
  return (
    <Typography align={align} variant={theme.products.price.installment.variant} classes={formattedPricesClasses}>
      {fullInstallmentText ? `${installmentsNumber.installments}x de ` : `em até ${installmentsNumber.installments}x`}
      {fullInstallmentText && (
        <Typography component="span" classes={formattedPricesClasses} variant="body2">
          {installmentFormattedPrice}
        </Typography>
      )}
    </Typography>
  );
}

InstallmentText.defaultProps = {
  fontSize: 14,
  fullInstallmentText: true,
};

InstallmentText.propTypes = {
  fontSize: PropTypes.number,
  fullInstallmentText: PropTypes.bool,
};

export default InstallmentText;
