import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useButtonStyles, useGridStyles, useLabelStyles } from './styles';

function TextButton(props) {
  const { buttonProps, buttonStyles, gridProps, gridStyles, labelStyles, label, disabledProps } = props;

  const gridClasses = useGridStyles({ ...gridStyles });
  const labelClasses = useLabelStyles({ ...labelStyles });
  const buttonClasses = useButtonStyles({ ...buttonStyles }, { ...disabledProps });

  return (
    <Button fullWidth classes={buttonClasses} {...buttonProps}>
      <Grid item container classes={gridClasses} {...gridProps}>
        <Typography align="center" classes={labelClasses}>
          {label}
        </Typography>
      </Grid>
    </Button>
  );
}

export default TextButton;
