import React from 'react';
import { Box } from '@material-ui/core';
import Styles from './styles';

function SomaTag(props) {
  const { tagProps, tagStyles, label } = props;
  const tagClasses = Styles.useSomaTag({ ...tagStyles });

  return (
    <Box classes={tagClasses} {...tagProps}>
      {label}
    </Box>
  );
}

export { SomaTag };
