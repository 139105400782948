import React, { useState, createContext, useContext, useCallback } from 'react';

const SnackBarContext = createContext();

function SnackbarContextProvider({ children }) {
  const snackState = useState({
    open: false,
    type: '',
    text: '',
  });

  return <SnackBarContext.Provider value={snackState}>{children}</SnackBarContext.Provider>;
}

const useSnackbarContext = () => {
  const [snackState, setSnackState] = useContext(SnackBarContext);
  const { open: snackBarOpened, text: snackBarText, type: snackBarTypeAlert } = snackState;

  const sendSnack = useCallback(
    (text, type) => {
      setSnackState({
        text,
        type,
        open: true,
      });
    },
    [setSnackState]
  );

  const closeSnack = useCallback(() => {
    setSnackState((oldState) => ({
      ...oldState,
      open: false,
    }));
  }, [setSnackState]);

  return { sendSnack, closeSnack, snackBarOpened, snackBarText, snackBarTypeAlert };
};

export { SnackbarContextProvider, useSnackbarContext };
