import React from 'react';
import { deviceType } from 'react-device-detect';
import { DesktopImage } from './desktop';
import { MobileImage } from './mobile';

function SomaImage(props) {
  const portrait = deviceType === 'mobile';

  return portrait ? <MobileImage {...props} /> : <DesktopImage {...props} />;
}

export default SomaImage;
