import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import flagIcon from './flagIcon.svg';
import Styles from './styles';

function ImportantWarning() {
  const containerClasses = Styles.useContainerStyle();
  const titleClasses = Styles.useTitleStyle();

  return (
    <Grid classes={containerClasses}>
      <img src={flagIcon} alt="" style={{ marginLeft: 15 }} />
      <Typography classes={titleClasses}>
        atenção deixar na mochila não reserva peças, finalize seu pedido antes que acabe
      </Typography>
    </Grid>
  );
}

export default ImportantWarning;
