import {
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useTheme,
  DialogActions,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useProductPanelContext } from '../../../contexts/productPanelContext';
import { somaEvents, useEventDispatch } from '../../../events';
import SomaDrawer from '../../organisms/SomaDrawer';
import orderMaps from './orderMaps';
import config from '../../../config';
import {
  useButtonSelectFilterStyles,
  useCloseModalStyles,
  useDialogTitleStyles,
  useFiltersQntNotificationStyles,
  useGridContentStyles,
  useDialogContainerStyles,
  useButtomStyles,
  useGridButtomCleanStyles,
  useGridButtomConfirmStyles,
  useDialogContentStyles,
  useGridCloseIconStyles,
  useGridToogleStyles,
  useDialogActionsStyles,
  useFilterButtonStyle,
  filterProductTitleStyle,
} from './styles';
import TextButton from '../SomaClickable/textButton';

function ProductFilterContent({ categoryProducts, filterOptions, filtersTitles, setOpenFilterModal }) {
  const theme = useTheme();
  const {
    addFilter,
    removeFilter,
    productsPanelState,
    clearFiltersAndSearch,
    clearSearch,
    setProductsFiltered,
    setHelperText,
  } = useProductPanelContext();

  const dispatchEvent = useEventDispatch();
  const portrait = useMediaQuery('(orientation: portrait)');
  const { filterBy } = productsPanelState;
  const selectFilterClasses = useButtonSelectFilterStyles();
  const dialogTitleClasses = useDialogTitleStyles();
  const gridContentClasses = useGridContentStyles();
  const buttomClasses = useButtomStyles();
  const gridButtomCleanClasses = useGridButtomCleanStyles();
  const gridButtomConfirmClasses = useGridButtomConfirmStyles();
  const dialogContentClasses = useDialogContentStyles();
  const gridToogleClasses = useGridToogleStyles();
  const filterButtonClasses = useFilterButtonStyle(theme);

  const selectFilter = (type, filter) => {
    if (!filterBy[type]) return;
    const alreadyHasFilter = !!filterBy[type].find((item) => item === filter);

    const filterActionSelector = new Map([
      [true, () => removeFilter(type, filter)],
      [false, () => addFilter(type, filter)],
    ]);

    filterActionSelector.get(alreadyHasFilter)();
  };

  const sortArray = (array, filterType) => {
    const separatedArrayPerType = array.reduce(
      (acc, item) => {
        const isClothOrShoeNumber = (string) => !/[^\s0-9]/gi.test(string);
        const accurateIndexOfItem = orderMaps[filterType].get(item.toLowerCase());

        if (isClothOrShoeNumber(item)) {
          acc[1].push(item);
          return acc;
        }

        if (accurateIndexOfItem) {
          acc[0][accurateIndexOfItem] = item;
          return acc;
        }

        acc[0].push(item);
        return acc;
      },
      [[], []]
    );

    return separatedArrayPerType[0].concat(separatedArrayPerType[1].sort());
  };

  const throwErrorOnFilter = (hasFiltersActive) => {
    if (hasFiltersActive) {
      setProductsFiltered([]);
      setHelperText(theme.productSearch.errorText, true);
      return;
    }
    clearFiltersAndSearch();
  };

  const filterProducts = () => {
    clearSearch();

    const sizeFilters = new Set(filterBy.sizes);
    const productHasProperties = (item) => sizeFilters.has(item.size);
    const isAvailableQnt = (item) => item.availableQuantity > 0;

    const filteredProducts = categoryProducts.reduce((acc, product) => {
      const hasProductByFilter = product.items.filter((item) => productHasProperties(item) && isAvailableQnt(item));

      if (hasProductByFilter.length) acc.push(product);
      return acc;
    }, []);

    if (filteredProducts && filteredProducts.length) {
      dispatchEvent(somaEvents.onProductsFiltered, {
        filters: [...sizeFilters].toString(),
      });

      setProductsFiltered(filteredProducts);
    } else throwErrorOnFilter(sizeFilters.size);

    setOpenFilterModal(false);
  };

  return (
    <>
      {filtersTitles.map((title) => (
        <DialogContent key={title} classes={dialogContentClasses} style={{ padding: 0 }}>
          {!portrait && (
            <DialogTitle disableTypography classes={dialogTitleClasses}>
              {theme.products.filters.titles[title]}
            </DialogTitle>
          )}
          <Grid classes={gridToogleClasses}>
            <Grid container direction="row" spacing={1} classes={gridContentClasses}>
              {sortArray(filterOptions[title], title).map((item) => (
                <Grid
                  container
                  item
                  xs={2}
                  md={2}
                  lg={2}
                  key={item}
                  style={{
                    height: 0,
                    padding: 0,
                    marginTop: 0,
                    marginBottom: '40px',
                    maxWidth: theme.products.filters.ToggleButton.minWidth,
                  }}
                >
                  <ToggleButton
                    value={item}
                    classes={selectFilterClasses}
                    selected={!!filterBy[title].find((filter) => filter === item)}
                    onChange={() => selectFilter(title, item)}
                  >
                    {item.toUpperCase()}
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid classes={buttomClasses}>
            <Grid classes={gridButtomCleanClasses}>
              <TextButton
                label={theme.products.filter.buttonClearFilters.label}
                labelStyles={{ ...filterButtonClasses.label, color: theme.palette.secondary.main }}
                buttonStyles={{ ...filterButtonClasses.modalButton }}
                buttonProps={{
                  onClick: clearFiltersAndSearch,
                  disabled: !filterBy.sizes.length,
                }}
              />
            </Grid>
            <Grid classes={gridButtomConfirmClasses}>
              <TextButton
                variant="outlined"
                label={theme.products.filter.buttonConfirm.label}
                labelStyles={{ ...filterButtonClasses.label, color: theme.palette.secondary.main }}
                buttonStyles={{ ...filterButtonClasses.modalButton }}
                buttonProps={{
                  disableElevation: true,
                  disableFocusRipple: true,
                  disableRipple: true,
                  disabled: !filterBy.sizes.length,
                  onClick: filterProducts,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      ))}
    </>
  );
}

// ################ ################ ################################ ################ ################ ################

function FilterProducts({ filterOptions, categoryProducts }) {
  const { productsPanelState, clearFiltersAndSearch } = useProductPanelContext();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [widthMenu, setWidthMenu] = useState(180);
  const { filterBy, productsFiltered } = productsPanelState;

  const theme = useTheme();
  const badgeClasses = useFiltersQntNotificationStyles();
  const closeModalClasses = useCloseModalStyles();
  const dialogContainerClasses = useDialogContainerStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const filterButtonClasses = useFilterButtonStyle(theme);
  const filterProductTitleClasses = filterProductTitleStyle(theme);
  const gridCloseIconClasses = useGridCloseIconStyles();

  const closeIcon = `${config.assetsUrl}/farm/closeIcon.svg`;
  const filtersTitles = Object.keys(filterOptions);
  const filtersActive = filtersTitles.reduce((acc, item) => {
    acc += filterBy[item].length;
    return acc;
  }, 0);

  const cancelFilter = () => {
    if (!productsFiltered) clearFiltersAndSearch();
    setOpenFilterModal(false);
  };

  useEffect(() => clearFiltersAndSearch(), [clearFiltersAndSearch]);

  const portrait = useMediaQuery('(orientation: portrait)');
  const landscape = useMediaQuery('(orientation: landscape)');
  useEffect(() => {
    if (landscape) {
      setWidthMenu(document.getElementById('pageMenu').offsetWidth);
    }
  }, [landscape]);

  return (
    <>
      <TextButton
        buttonProps={{
          onClick: () => setOpenFilterModal(true),
          endIcon: <Badge badgeContent={filtersActive} classes={badgeClasses} />,
        }}
        labelStyles={filterProductTitleClasses}
        label={theme.products.filter.sideHelper.label}
        buttonStyles={{ ...filterButtonClasses.button }}
      />

      {!portrait && (
        <Dialog
          classes={dialogContainerClasses}
          open={openFilterModal}
          onBackdropClick={cancelFilter}
          style={{ marginLeft: widthMenu }}
        >
          <DialogActions classes={dialogActionsClasses}>
            <Grid classes={gridCloseIconClasses}>
              <IconButton classes={closeModalClasses} onClick={cancelFilter}>
                <img src={closeIcon} alt="closeIcon" width="12px" />
              </IconButton>
            </Grid>
          </DialogActions>
          <ProductFilterContent
            categoryProducts={categoryProducts}
            filterOptions={filterOptions}
            filtersTitles={filtersTitles}
            setOpenFilterModal={setOpenFilterModal}
          />
        </Dialog>
      )}
      {portrait && (
        <SomaDrawer
          padding={theme.drawer.padding}
          setIsOpen={setOpenFilterModal}
          isOpen={openFilterModal}
          anchorDirection="bottom"
          titleDrawer="teste"
          width="100%"
          borderRadius={theme.products.filters.shape.borderRadius}
          maxHeight="90%"
          closeSelfButton
        >
          <ProductFilterContent
            categoryProducts={categoryProducts}
            filterOptions={filterOptions}
            filtersTitles={filtersTitles}
            setOpenFilterModal={setOpenFilterModal}
          />
        </SomaDrawer>
      )}
    </>
  );
}

export default FilterProducts;
