import React, { useState, useRef } from 'react';
import { somaEvents, useEventDispatch } from '../../../events';
import { useDesktopStyles } from './styles';

function DesktopImage({ width, height, key, src: lowResImage, imageOnZoom: highResImage }) {
  const [showZoom, setShowZoom] = useState();
  const [images, setImages] = useState({ src: lowResImage, zoom: lowResImage });
  const classes = useDesktopStyles({ width, height, showZoom, src: images.zoom });
  const dispatchEvent = useEventDispatch();
  const divRef = useRef(null);
  const contador = useRef(0);

  const onMouseMove = (e) => {
    const elem = e.currentTarget;
    const { top, left } = elem.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;

    divRef.current.style.backgroundPosition = `${x}% ${y}%`;
  };

  const changeImageResolution = () => {
    contador.current += 1;
    if (contador.current === 1 && images.src !== highResImage)
      setImages((oldImagesObj) => ({ ...oldImagesObj, src: highResImage }));

    if (contador.current === 2) {
      setImages((oldImagesObj) => ({ ...oldImagesObj, zoom: highResImage }));
    }
  };

  return (
    <div key={key} className={classes.zoom} ref={divRef}>
      <img
        width={width}
        height={height}
        alt=""
        src={images.src}
        onLoad={changeImageResolution}
        className={classes.img}
        onMouseEnter={() => {
          setShowZoom(true);
          dispatchEvent(somaEvents.onProductZoom);
        }}
        onMouseLeave={() => setShowZoom(false)}
        onMouseMove={onMouseMove}
      />
    </div>
  );
}

export { DesktopImage };
