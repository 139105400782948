import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ProductPrice } from '../../molecules';
import SomaInstallmentText from '../../molecules/InstallmentText';
import Styles from './styles';
import TextButton from '../../molecules/SomaClickable/textButton';

function ProductInfoBar(props) {
  const {
    product,
    addToBagProps,
    textsFontSize,
    gridStyles,
    productNameStyles,
    priceStyles,
    gridPriceStyle,
    installmentStyle,
    discontPriceColor,
    fullInstallmentText,
  } = props;

  const gridClasses = Styles.useGridStyle({ ...gridStyles });
  const productNameClasses = Styles.useProductNameStyle({ textsFontSize, ...productNameStyles });

  return (
    <Grid container classes={gridClasses}>
      <Box>
        <Typography noWrap classes={productNameClasses}>
          {product.productName}
        </Typography>
        <ProductPrice
          product={product}
          priceStyles={priceStyles}
          gridPriceStyle={gridPriceStyle}
          discontPriceColor={discontPriceColor}
          fontSize={textsFontSize}
        />
        <SomaInstallmentText
          value={product.price}
          align="left"
          product={product}
          fontSize={textsFontSize}
          installmentStyle={installmentStyle}
          fullInstallmentText={fullInstallmentText}
        />
      </Box>
      <TextButton {...addToBagProps} />
    </Grid>
  );
}

ProductInfoBar.defaultProps = {
  textsFontSize: 12,
};

ProductInfoBar.propTypes = {
  textsFontSize: PropTypes.number,
};

export default ProductInfoBar;
