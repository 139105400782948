import React, { useState, createContext, useContext, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import SomaliveAPIClient from '../services/somaliveAPI';
import { useEventDispatch, somaEvents, errorTypes } from '../events';
import { getDevice } from '../helpers/eventHelper';

const BagContext = createContext();

function BagContextProvider({ children }) {
  const bagState = useState({
    bagBadgeCount: 0,
  });

  return <BagContext.Provider value={bagState}>{children}</BagContext.Provider>;
}

const useBagContext = () => {
  const [bagState, setBagStateOriginal] = useContext(BagContext);
  const { bagBadgeCount } = bagState;
  const dispatchEvent = useEventDispatch();
  const simulationRequest = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const executeCartSimulation = useCallback(
    debounce(async (updatedBagState) => {
      if (!updatedBagState.cartSimulationData) return;
      const { products } = updatedBagState;
      const { brandName, salesChannel, exceptions, setSimulatedCart } = updatedBagState.cartSimulationData;
      if (simulationRequest.current?.abortController) {
        simulationRequest.current.abortController.abort();
      }
      if (products?.length > 0) {
        simulationRequest.current = await new SomaliveAPIClient().getCartSimulation(
          products,
          brandName,
          exceptions,
          salesChannel
        );
        simulationRequest.current.request.then(setSimulatedCart).catch((error) => {
          dispatchEvent(somaEvents.onError, {
            type: errorTypes.requestError,
            message: `Failed get cartSimulation: ${error.message}`,
            path: 'bagContext.js -> executeCartSimulation()',
            device: getDevice(),
            stack: error.stack,
          });
        });
      }
    }, 1000),
    [dispatchEvent]
  );

  const setBagState = useCallback(
    (state) => {
      const { products = [] } = state;
      const count = products
        .map((product) => product.bagInfo?.quantity)
        .reduce((previous, current) => previous + current, 0);

      setBagStateOriginal((previousState) => {
        const updatedState = { ...previousState, ...state, products, bagBadgeCount: count };
        executeCartSimulation(updatedState);
        return updatedState;
      });
    },
    [setBagStateOriginal, executeCartSimulation]
  );

  const setBagBadgeCount = useCallback(
    (count) => {
      setBagStateOriginal((previousState) => {
        const state = { ...previousState, bagBadgeCount: count };
        executeCartSimulation(state);
        return state;
      });
    },
    [setBagStateOriginal, executeCartSimulation]
  );

  return { bagBadgeCount, setBagState, setBagBadgeCount };
};

export { BagContextProvider, useBagContext };
