import React from 'react';
import { Card, CardContent, Slide, CardHeader, useTheme, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import Styles from './styles';
import config from '../../../../config';

function SomaChatCard(props) {
  const { open, onClose, raised, enteredOnChat, children } = props;
  const theme = useTheme();

  const cardClasses = Styles.useCardStyle({ enteredOnChat });
  const cardContentClasses = Styles.useCardContentStyle();
  const headerClasses = Styles.useHeaderStyle({ enteredOnChat, theme });
  const headerClassesMobileLandscape = Styles.useHeaderStyleMobileLandscape(theme);
  const closeButtonClasses = Styles.useCloseButtonStyles();
  const isMobileLandscape = useMediaQuery('(max-width:926px) and (orientation: landscape)');
  const closeChatIcon = `${config.assetsUrl}/common/icons/close_chat_icon.svg`;

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Card raised={raised} classes={cardClasses}>
        <CardHeader
          action={
            <Button onClick={onClose} disableRipple disableFocusRipple classes={closeButtonClasses}>
              <img src={closeChatIcon} alt="close_chat" width="16.86px" />
            </Button>
          }
          classes={isMobileLandscape ? headerClassesMobileLandscape : headerClasses}
        />
        <CardContent classes={cardContentClasses}>{children}</CardContent>
      </Card>
    </Slide>
  );
}

SomaChatCard.defaultProps = {
  open: true,
  raised: false,
};

SomaChatCard.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  raised: PropTypes.bool,
};

export default SomaChatCard;
