import { Badge, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import {
  useComposedButtonStyles,
  useLabelStyles,
  useFigureStyles,
  useBadgeStyles,
  useGridStyles,
  useImgStyles,
} from './styles';

function ComposedButton(props) {
  const {
    buttonStyles,
    buttonProps,
    badgeStyles,
    badgeCount,
    labelStyles,
    label,
    gridStyles,
    gridProps,
    figureChar,
    figureStyles,
    src,
    figure,
    iconType,
  } = props;

  const buttonClasses = useComposedButtonStyles({ ...buttonStyles });
  const gridClasses = useGridStyles({ ...gridStyles });
  const badgeClasses = useBadgeStyles({ ...badgeStyles });
  const figureClasses = useFigureStyles({ ...figureStyles, figureChar });
  const labelClasses = useLabelStyles({ ...labelStyles });
  const imgClasses = useImgStyles({ ...figureStyles, figureChar });

  return (
    <Button classes={buttonClasses} {...buttonProps}>
      <Grid container {...gridProps}>
        <Grid container justify="center" classes={gridClasses}>
          {iconType === 'figure' && (
            <Badge badgeContent={badgeCount} classes={badgeClasses}>
              {figure ?? <Typography classes={figureClasses} />}
            </Badge>
          )}
          {iconType === 'img' && (
            <Badge badgeContent={badgeCount} classes={badgeClasses}>
              <img src={src} alt="icon" height={29} width={29} className={imgClasses.root} />
            </Badge>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography classes={labelClasses}>{label}</Typography>
        </Grid>
      </Grid>
    </Button>
  );
}

ComposedButton.defaultProps = {
  iconType: 'figure',
};

ComposedButton.propTypes = {
  iconType: PropTypes.oneOf(['figure', 'img']),
};

export default ComposedButton;
