import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';

function SelectedSize(props) {
  const { size, color } = props;
  const selectedSizeClasses = Styles.useSelectedSize();

  return (
    <Box m={2}>
      <Typography color={color} classes={selectedSizeClasses}>
        {size}
      </Typography>
    </Box>
  );
}

SelectedSize.defaultProps = {
  color: 'primary',
};

SelectedSize.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
};

export default SelectedSize;
