import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Style from './styles';
import FigureButton from '../SomaClickable/figureButton';

function MuteButton(props) {
  const { isMuted, onClickMute } = props;
  const theme = useTheme();
  const buttonClasses = Style.buttonStyle(theme);
  const muteImageSizeClasses = Style.useMuteImageSizeStyle();
  const portrait = useMediaQuery('(orientation: portrait)');

  const iconManager = () => {
    if (portrait) {
      return isMuted
        ? theme.mutePlayer.mobileIcons.mobileUnmuteIcon.src
        : theme.mutePlayer.mobileIcons.mobileMuteIcon.src;
    }

    return isMuted
      ? theme.mutePlayer.desktopIcons.desktopUnmuteIcon.src
      : theme.mutePlayer.desktopIcons.desktopMuteIcon.src;
  };

  return (
    <FigureButton
      figure={<img src={iconManager()} alt="mute_player" className={muteImageSizeClasses.root} />}
      buttonProps={{ onClick: onClickMute, disableRipple: true, disableFocusRipple: true }}
      buttonStyles={{ ...buttonClasses }}
    />
  );
}

MuteButton.defaultProps = {
  isMuted: true,
};

MuteButton.propTypes = {
  isMuted: PropTypes.bool,
  onClickMute: PropTypes.func.isRequired,
};

export default MuteButton;
