import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEvent } from '../../../events/useEvent';
import { Heart } from './class';
import './styles.css';

function ReactionAssets(props) {
  const { src, alt, refTarget: reactionButtonRef } = props;
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const isMobile = useMediaQuery('(orientation: portrait)');
  // const reactionsCounter = useRef(0);
  const listOfHearts = useRef([]);
  const theme = useTheme();

  const createHearts = (color, quantity = 1) => {
    const othersReaction = color === theme.reaction.colors.othersReaction;
    if (listOfHearts.length > 20 && othersReaction) return;

    for (let i = 0; i < quantity; i += 1) {
      setTimeout(() => {
        listOfHearts.current.push(new Heart(reactionButtonRef, isMobile, color));
        if (!shouldAnimate) setShouldAnimate(true);
      }, i * 150);
    }
  };

  const heartAnimationLoop = useCallback(() => {
    listOfHearts.current.forEach((heart, index) => {
      if (heart.wasAnimated) {
        heart.remove();
        listOfHearts.current.splice(index, 1);
      } else {
        heart.update();
        heart.draw();
      }
    });

    if (listOfHearts.current.length === 0) {
      setShouldAnimate(false);
      return;
    }

    requestAnimationFrame(heartAnimationLoop);
  }, [listOfHearts]);

  // const animationBuffer = () => {
  //   reactionsCounter.current += 1;

  //   if (reactionsCounter.current > 10) {
  //     createHearts(theme.reaction.colors.othersReaction);
  //     reactionsCounter.current -= 10;
  //   }
  // };

  useEvent('myReaction', () => {
    createHearts(theme.reaction.colors.myReaction, 3);
  });

  useEvent('othersReaction', () => {
    /*
      Caso seja necessário limitar a quantidade de corações brancos por reação
      descomente a função animationBuffer e use ela no lugar da função createHearts
      dentro desse event.
      animationBuffer();
    */
    createHearts(theme.reaction.colors.othersReaction);
  });

  useEffect(() => {
    if (shouldAnimate) heartAnimationLoop();
  }, [heartAnimationLoop, shouldAnimate]);

  return <img src={src} alt={alt} height="13.22" width="16px" />;
}

export default ReactionAssets;
