import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';
import config from '../../../config';

function HeaderDrawer(props) {
  const { onClickClose, icon, showBackButton, onClickBack } = props;

  const boxClasses = Styles.useBoxStyle();
  const closeButtonClasses = Styles.useCloseButtonStyle();
  const arrowButtonClasses = Styles.useArrowButtonStyle();
  const closeButtonContainerClasses = Styles.useCloseButtonContainerStyle();
  const arrowButtonContainerClasses = Styles.useArrowButtonContainerStyle();
  const closeIcon = `${config.assetsUrl}/farm/closeIcon.svg`;
  const backIcon = `${config.assetsUrl}/common/icons/Union.svg`;

  return (
    <Box classes={boxClasses}>
      {!!icon && (
        <Box>
          <img src={icon.src} alt={icon.alt} />
        </Box>
      )}
      {!icon && (
        <>
          {showBackButton && (
            <Box classes={arrowButtonContainerClasses}>
              <Button onClick={onClickBack} classes={arrowButtonClasses} disableRipple disableFocusRipple>
                <img src={backIcon} alt="backIcon" />
              </Button>
            </Box>
          )}
          <Box classes={closeButtonContainerClasses}>
            <Button onClick={onClickClose} classes={closeButtonClasses} disableRipple disableFocusRipple>
              <img src={closeIcon} alt="closeIcon" />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

HeaderDrawer.propTypes = {
  onClickClose: PropTypes.func.isRequired,
};

export default HeaderDrawer;
