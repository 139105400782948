import React, { useState, useEffect, useRef } from 'react';
import { Box, Dialog, Button, useTheme, IconButton, useMediaQuery } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import Styles from './styles';
import { useEventDispatch, somaEvents } from '../../../events';
import { SomaImage } from '../../atoms';
import { SomaCarousel } from '../../molecules';

function ProductImages(props) {
  const { product } = props;
  const { images, isShowcasing } = product;

  const imagesStd = useRef(Array.from(images.std));
  const imagesHd = useRef(Array.from(images.hd));

  const theme = useTheme();
  const portrait = useMediaQuery('(orientation: portrait)');
  const mainPicture = imagesStd.current[0];

  const [showCarousel, setShowCarousel] = useState(false);
  const [pictureViewing, setPictureViewing] = useState(mainPicture);
  const [pictureToZoom, setPictureToZoom] = useState(imagesHd.current[0]);
  const buttonClasses = Styles.useButtonStyles();

  const boxClasses = Styles.useBoxStyles();
  const dialogClasses = Styles.useDialogStyles();
  const imageClasses = Styles.useImageStyle();
  const closeIconClasses = Styles.useCloseIconStyles(theme);
  const closeIconButtonClasses = Styles.useCloseIconButtonStyle();
  const carouselClasses = Styles.useImageGridStyle();
  const dispatchEvent = useEventDispatch();

  const [imgWidth, imgHeight] = ['100%', '100%'];

  const regExp1 = /v=[0-9]+/;

  const getPictureId = (img) => regExp1.exec(img)[0];

  const onSelectPicture = (picture) => {
    const pictureId = getPictureId(picture);

    setPictureViewing(picture);
    setPictureToZoom(imagesHd.current.find((item) => item.includes(pictureId)));
  };

  useEffect(() => {
    if (showCarousel) {
      setPictureViewing(imagesStd.current[0]);
    }
  }, [showCarousel, imagesStd, imagesHd, setPictureViewing, setPictureToZoom]);

  if (!mainPicture) return null;

  return (
    <Box flex={1} classes={boxClasses}>
      <Button
        onClick={() => {
          if (showCarousel) return;

          setShowCarousel(true);

          if (isShowcasing) dispatchEvent(somaEvents.onProductShowcasingClicked, { product });
        }}
        classes={buttonClasses}
      >
        <img key={mainPicture} src={mainPicture} alt="" width="100%" className={imageClasses.mainPicture} />
      </Button>
      <Dialog
        fullWidth
        maxWidth={portrait ? 'xs' : 'sm'}
        open={showCarousel}
        onClose={() => {
          setPictureViewing(imagesStd.current[0]);

          setShowCarousel(false);
        }}
        classes={dialogClasses}
      >
        <IconButton classes={closeIconButtonClasses} onClick={() => setShowCarousel(false)}>
          <CloseIcon classes={closeIconClasses} />
        </IconButton>

        <Box classes={carouselClasses}>
          <SomaCarousel
            imagesArray={imagesStd.current}
            imageViewing={pictureViewing}
            product={product}
            onSelectPicture={onSelectPicture}
          />
          <SomaImage
            key={pictureViewing}
            src={pictureViewing}
            imageOnZoom={pictureToZoom}
            width={imgWidth}
            height={imgHeight}
          />
        </Box>
      </Dialog>
    </Box>
  );
}

export default withWidth()(ProductImages);
ProductImages.defaultProps = {
  images: {
    std: [],
    hd: [],
  },
};
